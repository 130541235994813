import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectAll', 'childCheckbox', 'submitButtonMasive', 'idsField', 'formMassive']

  connect() {
    this.updateSelectAll()
  }

  updateSelectAll() {
    const { allChecked, noneChecked } = this.childCheckboxTargets.reduce(
      (acc, checkbox) => {
        acc.allChecked = acc.allChecked && checkbox.checked
        acc.noneChecked = acc.noneChecked && !checkbox.checked
        return acc
      },
      { allChecked: true, noneChecked: true }
    )

    if (this.hasSelectAllTarget) {
      if (allChecked) {
        this.selectAllTarget.indeterminate = false
        this.selectAllTarget.checked = true
      } else if (noneChecked) {
        this.submitButtonMasiveTarget.classList.add('hidden')
        this.selectAllTarget.indeterminate = false
        this.selectAllTarget.checked = false
      } else {
        this.submitButtonMasiveTarget.classList.remove('hidden')
        this.selectAllTarget.indeterminate = true
        this.selectAllTarget.checked = false
      }
    }
    if (!noneChecked) {
      this.submitButtonMasiveTarget.classList.remove('hidden')
    }
  }

  updateCheckboxes(event) {
    if (event && event.target === this.selectAllTarget) {
      const isChecked = this.selectAllTarget.checked
      this.childCheckboxTargets.forEach(checkbox => {
        checkbox.checked = isChecked
      })

      if (isChecked) {
        this.submitButtonMasiveTarget.classList.remove('hidden')
      } else {
        this.submitButtonMasiveTarget.classList.add('hidden')
      }
    } else {
      this.updateSelectAll()
    }
  }

  sendMassiveReminder(event) {
    event.preventDefault()
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    const selectedIds = this.childCheckboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)
    if (selectedIds.length > 0) {
      fetch('/admin/seguimiento/send_reminder_massive', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify({
          ids: selectedIds
        })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Hubo un problema al procesar la solicitud.')
          }
          return response.json()
        })
        .then(data => {
          alert(data.message)
          location.reload()
        })
        .catch(error => {
          console.error('Error:', error)
        })
    }
  }
}
