import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element", "description"];

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = !el.disabled;
    });
  }

  organ(evt) {
    let reportOrganSelect = null;
    let derivationNumber = null;
    let reportsOrgan = document.querySelectorAll('[id^="report_report_organs_attributes_"][id$="organ_id"]');
    reportsOrgan.forEach(reportOrgan => {
      if (reportOrgan.value == evt.currentTarget.dataset.organId) {
        reportOrganSelect = reportOrgan;
        derivationNumber = document.getElementById(reportOrgan.id.substr(0, reportOrgan.id.indexOf("organ_id")) + "derivation_number");
        reportOrganSelect.disabled = !(evt.currentTarget.checked)
        derivationNumber.disabled = !(evt.currentTarget.checked)
        return;
      }
    })
  }

  grant_protective(event) {
    const description = this.descriptionTarget;
    event.currentTarget.checked ? description.classList.remove("hidden") : description.classList.add("hidden");
  }

  enable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = false;
      el.classList.remove("disabled"); //en caso el elemento tenga "disabled: true" en su html ya que en su class se quedará pegada la clase disabled
    });
  }

  disable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = true;      
    });
  }

  enabledisable(event) {
    this.elementTargets.forEach((el, i) => {
      el.disabled = false;
      if (event.target.value == el.getAttribute("data-key")) {
        el.disabled = true;
      }
    });
  }
}
