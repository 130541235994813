import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "doc_type",
    "labour_protection"
  ];

  search(event) {
    let doc_type = this.doc_typeTarget.value;
    if (doc_type == 'ruc') {
      this.labour_protectionTarget.parentNode.style.display = 'none'
    } else {
      this.labour_protectionTarget.parentNode.style.display = 'block'
    }
  }

}
