import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['element']

  openModal(_event) {
    const modal = document.getElementById('modal-not-report-confirm')
    modal.classList.remove('hidden')
  }

  closeModal(_event) {
    const modal = document.getElementById('modal-not-report-confirm')
    modal.classList.add('hidden')
  }
}
