import Choices from "choices.js";

document.addEventListener("turbolinks:load", () => {
  const elements = document.querySelectorAll(".js-choices");
  if (elements.length) {
  elements.forEach((el, _) => {
    let replaceContainer = false;
    if (el.dataset.choice) {
      el.removeAttribute("data-choice");
      replaceContainer = true;
    }
    const url = el.dataset.url;
    const configChoices = {
      removeItemButton: true,
      noChoicesText: "No hay opciones para elegir",
      noResultsText: "No se han encontrado resultados",
      itemSelectText: "Seleccionar",
      placeholder: true,
      searchPlaceholderValue: el.className.split(" ").includes("write-here") ? "Escribe aquí" : "Buscar",
      searchResultLimit: 25
    };

    if (el.className.split(" ").includes("search")) {
      //choices options
      configChoices["classNames"] = {
        containerInner: `choices__inner__custom ${
          el.className.split(" ").includes("form") ? "form" : ""
        }`,
        placeholder: "placeholder-gray-700",
      };
    }

    const choicesDocuments = new Choices(el, configChoices);
    if (replaceContainer) {
      let father = el.parentNode.parentNode;
      let grandfather = father.parentNode.parentNode;
      grandfather.replaceWith(father);
    }

    if (el.className.split(" ").includes("search") && url) {
      const handleSearch = async (event, choicesDocuments) => {
        try {
          const response = await fetch(url + event.detail.value, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });
          const items = await response.json();
          const options = items.map((item) => {
            if (item.slug) {
              return { value: item.id, label: item.name, customProperties: item.slug };
            }
            return { value: item.id, label: item.name };
          });
          choicesDocuments.setChoices(options, "value", "label", true);
        } catch (err) {
          console.error(err);
        }
      };
      el.addEventListener(
        "search",
        (event) => {
          handleSearch(event, choicesDocuments);
        },
        false
      );
    }

    el.addEventListener(
      "change",
      (event) => {
        if (event.target.length) {
          if(event.target[0].innerText.length) {
            const hiddenNameElement = document.querySelector(`.hidden_name_${el.id}`);
            if (hiddenNameElement) {
              const institutionId = document.getElementById(el.id);
              const institutionIdJs = document.getElementById('report_institution_id_js');
              if (institutionIdJs && institutionIdJs.length)
                institutionIdJs.value = institutionId.value;
              hiddenNameElement.value = event.target[0].innerText;
            }
          }
          if(event.target[0].dataset && event.target[0].dataset.customProperties.length) {
            const hiddenSigleElement = document.querySelector(`.hidden_sigle_${el.id}`);
            if (hiddenSigleElement) {
              hiddenSigleElement.value = event.target[0].dataset.customProperties
            }
          }
        }
      },
      false
    );
  });
  }
});
